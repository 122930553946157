<template>
  <div>
    <classifications-selector
      v-if="haveClassificationGroups"
      class="filter-container"
      :classificationGroups="classificationGroups"
      :selectedGroups="solutionsFilter"
      :categories="categories"
      :classifiersBySolution="classifiersBySolution"
      :selectedOptions="selectedClassificationOptions"
      :expandedGroups="expandedGroups"
      :useHeaders="true"
      :selectableGroups="true"
      :useCollapsibles="true"
      :openOnUse="true"
      :hideEmptyItems="true"
      :__fallbackId="__fallbackId"
      @update:selectedGroups="val => $emit('update:showSolutions', val)"
      @update:selectedOptions="val => selectedClassificationOptions = val"
      @update:expandedGroups="val => expandedGroups = val"
    />
    <div class="filter-container">
      <label>Inspection State</label>
      <div>
      <ul class="choice-option-list stretch-width">
        <li v-for="option in stateFilterOptions" :key="option" class="state-option">
          <span v-bind:title="option">
            <input type="checkbox" v-bind:id="'option-' + option" v-bind:value="option" v-model="chosenStates" class="choice-checkbox">
            <i v-bind:class="'material-icons annotation-unstyled ' + stateClass(option)">{{ stateIcon(option) }}</i>
          </span>
        </li>
      </ul>
      </div>
    </div>
    <div class="filter-container">
      <label for="filter-assigned-to">Inspection assigned to</label>
      <v-select
        id="filter-assigned-to"
        :options="allAssignedToOptions.filter(o => !selectedAssignedToOptions.includes(o.id))"
        :value="allAssignedToOptions.filter(o => selectedAssignedToOptions.includes(o.id))"
        :multiple="true"
        :searchable="false"
        :closeOnSelect="false"
        @input="val => selectedAssignedToOptions = val.map(o => o.id)">
        <span slot="no-options"></span>
      </v-select>
    </div>
    <div class="filter-container date-filter">
      <div>
        <input type="checkbox" id="useDateFilter" v-model="useDateFilter" class="choice-checkbox">
        <label for="useDateFilter" class="inline-block">Inspection completed date</label>
      </div>
      <input v-model="dateCompletedFrom" type="date" title="From date" class ="stretch-width">
      <input v-model="dateCompletedTo" type="date" title="To date" class ="stretch-width">  
    </div>
  </div>
</template>

<script>
  import classificationsSelector, { FALLBACK_ID } from '../classifications_selector/classifications_selector.vue'
  import vSelect from 'vue-select'
  import moment from 'moment'
  import _ from 'lodash'

  const FILTER_STATES = ['draft','active', 'finished']

  export default {
    components: {
      classificationsSelector,
      vSelect,
    },
    props: {
      classificationGroups: Object,
      solutionsFilter: Array,
      categories: Object,
      classifiersBySolution: Object,
      assignedToList: Array,
    },
    data: function () {
      return {
        stateFilterOptions: FILTER_STATES,
        chosenStates: FILTER_STATES,
        useDateFilter: false,
        dateCompletedFrom: "",
        dateCompletedTo: "",
        selectedClassificationOptions: {},
        selectedAssignedToOptions: [],
        expandedGroups: [...this.solutionsFilter ?? []],
      };
    },
    watch: {
      solutionsFilter: function (newValue) {
        this.updateFilter();
      },
      selectedClassificationOptions: function (newValue) {
        this.updateFilter();
      },
      selectedAssignedToOptions: function (newValue) {
        this.updateFilter();
      },
      useDateFilter: function (newValue) {
        this.updateFilter();
      },
      dateCompletedFrom: function (newValue) {
        this.updateFilter();
      },
      dateCompletedTo: function (newValue) {
        this.updateFilter();
      },
      chosenStates: function (newValue) {
        this.updateFilter();
      },
    },
    computed: {
      haveClassificationGroups: function() {
        return Object.keys(this.classificationGroups).length > 0;
      },
      __fallbackId: function() {
        return FALLBACK_ID;
      },
      allAssignedToOptions: function () {
        return this.assignedToList.map(a => ({id: a[0], label: a[1]}));
      },
    },
    mounted() {
      this.updateFilter();
    },
    methods: {
      updateFilter: function () {
        let vm = this;

        let solutionPredicate = (annotation) => {
          return _.includes(vm.solutionsFilter,annotation.inspection_summary.solution_type);
        }

        let classificationsPredicate = (annotation) => {
          let filterEntryToApply = Object.entries(vm.selectedClassificationOptions)
            .filter(entry => vm.solutionsFilter.includes(entry[0]) && annotation.inspection_summary.solution_type === entry[0])
            .shift();

          if (filterEntryToApply) {
            let fallbackCategories = vm.classifiersBySolution[filterEntryToApply[0]]
              .filter(ce => !ce.hasOwnProperty('exhaustive') || !ce.exhaustive)
              .map(ce => ce.reference);
            let classifications = annotation.inspection_summary.classifications;
            let classificationKeys = Object.keys(classifications);

            let filterEntries = Object.entries(filterEntryToApply[1]);
            for (let i = 0; i < filterEntries.length; i++) {
              let entry = filterEntries[i];
              let matched = classificationKeys.includes(entry[0]) && entry[1].includes(classifications[entry[0]])
                  || !classificationKeys.includes(entry[0]) && fallbackCategories.includes(entry[0]) && entry[1].includes(FALLBACK_ID);
              if (!matched) {
                return false;
              }
            }
          }
          return true;
        }

        let statePredicate = (annotation) => {
          let state = annotation.inspection_summary.process_state
          if (state == 'completed' || state == 'reporting') {
            state = 'finished'
          }
          return _.includes(vm.chosenStates, state);
        }

        let assignedPredicate = (annotation) => {
          let opts = vm.selectedAssignedToOptions;
          return (opts.length ===0 || opts.includes(annotation.inspection_summary.assigned_to.user_id));
        }

        let completedFromMoment = moment(vm.dateCompletedFrom)
        let completedToMoment = moment(vm.dateCompletedTo)
        
        let completedDatePredicate = (annotation) => {
          if (vm.useDateFilter) {
            let dueDate = moment(annotation.inspection_summary.completed_at)
            return (!completedFromMoment.isValid() || dueDate.isAfter(completedFromMoment))
              && (!completedToMoment.isValid() || dueDate.isBefore(completedToMoment))
          } else {
            return true
          }
        }

        let predicate = _.overEvery(solutionPredicate, classificationsPredicate, statePredicate, assignedPredicate, completedDatePredicate)
        
        this.$emit('update:filter', predicate)
      },
      stateClass: function (state) {
        switch (state) {
          case 'active': return 'annotation-active';
          case 'completed':
          case 'reporting':
          case 'finished': return 'annotation-finished';
          default: return 'annotation-draft';
        }
      },
      stateIcon: function(state) {
        switch (state) {
          case 'active': return 'loop';
          case 'completed':
          case 'reporting':
          case 'finished': return 'check_circle';
          default: return 'lens';
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .choice-option-list {
    list-style-type: none;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .choice-option-list label {
    display: inline-block;
  }
  .state-option {
    display: inline-block;
    margin-right: 15px;
    * {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      column-gap: 4px;
    }
  }
  .inline-block {
    display: inline-block;
  }
  .choice-checkbox {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .filter-container {
    margin-top: 8px;
    margin-left: -6px;
    margin-right: -6px;
    padding:6px;
    background-color:#eee;
  }
  .stretch-width {
    width: auto;
  }
  .annotation-unstyled {
    font-size: unset;
  }
  .annotation-finished {
    color: #4CAF50;
  }
  .annotation-draft {
    color: #b9b8b8;
  }
  .annotation-active {
    color: #4CAF50;
  }
  #filter-assigned-to {
    height: auto;
  }
  .date-filter {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
</style>
<style>
/* vue-select unscoped */
  #filter-assigned-to .vs__dropdown-toggle {
    padding: 0;
    min-height: 32px;
  }
  #filter-assigned-to .vs__selected {
    margin: 3px 2px;
  }
  #filter-assigned-to .vs__selected-options input {
    margin: 0;
    padding: 0;
    height: auto;
    line-height: auto;
    border-width: 0;
    background-color: inherit;
  }
  #filter-assigned-to .vs__selected-options input:focus {
    box-shadow: none; /* hides the focus shadow */
  }
</style>